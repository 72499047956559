<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <v-row justify="space-between" align="center" class="mb-4 pl-3 mt-2">
        <h2>
          {{ inputs.language === 'en' ? 'Experiment 5: Question 4' : 'Expérience 5: Question 4' }}
        </h2>
        <v-btn-toggle
          v-model="inputs.language"
          class="ml-2"
          size="small"
          dense="dense"
          rounded="rounded"
          mandatory
        >
          <v-btn :value="'en'">English</v-btn>
          <v-btn :value="'fr'">Français</v-btn>
        </v-btn-toggle>
      </v-row>

      <p v-if="inputs.language === 'en'">
        In this experiment, you will carry out the electrophilic bromination of <i>E</i>-cinnamic
        acid:
      </p>

      <p v-else>
        Dans cette expérience, vous réaliserez la bromination électrophile de l'acide
        <i>E</i>-cinnamique :
      </p>

      <p class="pl-8 mb-3">
        <v-img style="max-width: 300px" :src="imageName1" />
      </p>

      <p class="mb-3">
        a)
        <b>{{
          inputs.language === 'en'
            ? 'Select all that apply.'
            : 'Sélectionnez toutes les bonnes réponses possibles.'
        }}</b>
        {{
          inputs.language === 'en'
            ? 'Which are the specific stereoisomers that will be formed in your reaction?'
            : 'Quels sont les stéréoisomères spécifiques qui seront formés lors de votre réaction ?'
        }}
      </p>

      <table style="width: 200px" class="pl-8">
        <tr v-for="part in parts" :key="part.imageUrl">
          <td style="width: 30px">
            <v-checkbox
              v-model="inputs.products"
              :key="`choice-${part}`"
              :value="part.value"
              hide-details
              :ripple="false"
            />
          </td>
          <td style="width: 170px">
            <v-img :src="part.imageUrl" width="33%" height="auto" />
          </td>
        </tr>
      </table>

      <p v-if="isFirstPartCorrect" class="mb-5 mt-5">
        b) Correct!
        {{
          inputs.language === 'en'
            ? 'Now please assign the R/S configuration of the stereocentres in the reaction products as labelled below:'
            : 'Maintenant, veuillez attribuer la configuration R/S des centres stéréogènes dans les produits de réaction comme indiqué ci-dessous :'
        }}
      </p>

      <p v-if="isFirstPartCorrect" class="mb-8 mb-5">
        <v-img style="max-width: 460px" :src="imageName2" />
      </p>

      <p v-if="isFirstPartCorrect" class="mb-2">
        {{ inputs.language === 'en' ? 'Stereocentre A:' : 'Centre stéréogène A:' }}
        <v-select
          v-model="inputs.configA"
          class="d-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="items"
        />
      </p>

      <p v-if="isFirstPartCorrect" class="mb-2">
        {{ inputs.language === 'en' ? 'Stereocentre B:' : 'Centre stéréogène B:' }}
        <v-select
          v-model="inputs.configB"
          class="d-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="items"
        />
      </p>

      <p v-if="isFirstPartCorrect" class="mb-2">
        {{ inputs.language === 'en' ? 'Stereocentre C:' : 'Centre stéréogène C:' }}
        <v-select
          v-model="inputs.configC"
          class="d-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="items"
        />
      </p>

      <p v-if="isFirstPartCorrect" class="mb-5">
        {{ inputs.language === 'en' ? 'Stereocentre D:' : 'Centre stéréogène D:' }}
        <v-select
          v-model="inputs.configD"
          class="d-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="items"
        />
      </p>

      <p v-if="isSecondPartCorrect" class="mb-2">
        c) Correct!
        {{
          inputs.language === 'en'
            ? 'What is the isomeric relationship between the two products formed?'
            : 'Quelle est la relation isomérique entre les deux produits formés ?'
        }}
      </p>

      <v-radio-group
        v-if="isSecondPartCorrect"
        v-model="inputs.isomerType"
        :disabled="!allowEditing"
        class="mb-0 pl-6"
      >
        <v-radio
          v-for="option in inputs.language === 'en' ? isomerOptions : isomerOptionsFr"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'uOttawa1321Prelab5Q4',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code

    return {
      inputs: {
        products: [],
        configA: null,
        configB: null,
        configC: null,
        configD: null,
        isomerType: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      parts: [
        {imageUrl: '/img/assignments/ottawaPrelab5Q4_part1_choiceA.png', value: 'A'},
        {imageUrl: '/img/assignments/ottawaPrelab5Q4_part1_choiceB.png', value: 'B'},
        {imageUrl: '/img/assignments/ottawaPrelab5Q4_part1_choiceC.png', value: 'C'},
        {imageUrl: '/img/assignments/ottawaPrelab5Q4_part1_choiceD.png', value: 'D'},
      ],
      items: [
        {text: 'R', value: 'R'},
        {text: 'S', value: 'S'},
      ],
      isomerOptions: [
        {text: 'Conformers', value: 'conformers'},
        {text: 'Enantiomers', value: 'enantiomers'},
        {text: 'Diastereomers', value: 'diastereomers'},
        {text: 'Meso Compound', value: 'meso'},
      ],
      isomerOptionsFr: [
        {text: 'Conformères', value: 'conformers'},
        {text: 'Énantiomères', value: 'enantiomers'},
        {text: 'Diastéréoisomères', value: 'diastereomers'},
        {text: 'Composé méso', value: 'meso'},
      ],
    };
  },
  computed: {
    imageName1() {
      return '/img/assignments/ottawaPrelab5Q4_part1_main.png';
    },
    imageName2() {
      return '/img/assignments/ottawaPrelab5Q4_part2.png';
    },
    isFirstPartCorrect() {
      return (
        this.inputs.products.includes('B') &&
        this.inputs.products.includes('C') &&
        !this.inputs.products.includes('A') &&
        !this.inputs.products.includes('D')
      );
    },
    isSecondPartCorrect() {
      return (
        this.inputs.configA === 'R' &&
        this.inputs.configB === 'S' &&
        this.inputs.configC === 'S' &&
        this.inputs.configD === 'R'
      );
    },
  },
};
</script>
<style scoped>
.v-input {
  margin-top: 0;
}
</style>
